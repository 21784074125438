const e = "-", c = (n) => typeof n == "string" ? n.replace(" ", "-") : n.join(e), r = (n, s) => `--${n.toLowerCase()}--${c(s)}`, i = (n, s, o) => `var(${r(n, s)}${o ? ", " + o : ""})`, l = (n, s) => {
  const o = typeof n == "string" ? n : n.displayName, t = i.bind(null, o, s);
  return t.toString = r.bind(null, o, s), t;
}, a = (n, s) => l(n, s);
export {
  l as cssToken,
  r as cssVar,
  i as getCssVar,
  a as globalCssToken
};
