import { cssToken as o } from "./utils.mjs";
import { s as c } from "./styled-egLbU9Lr.mjs";
const r = "Typography", g = {
  accentColor: o(r, ["color", "accent"])
}, n = "Container", l = {
  width: o(n, "width"),
  offset: o(n, "offset")
}, s = "Article", a = {
  gap: o(s, "gap")
}, e = c("article", { name: s })(({ theme: t }) => ({
  ...t.typography.default,
  p: {
    ...t.typography.default,
    marginBlockStart: a.gap(),
    marginBlockEnd: a.gap()
  },
  section: {
    marginBlockStart: a.gap(),
    marginBlockEnd: a.gap()
  }
}));
e.displayName = s;
export {
  e as A,
  g as C,
  l as a,
  a as b,
  n as c,
  r as n
};
